import { type DealAttachmentQl } from 'src/data/api/graphql/br_process/generated/graphql-sdk';

export const DEAL_ATTACHMENTS_LIMIT = 20;

export enum EDealAttachmentType {
    FILE = 'FILE',
    URL = 'URL',
}

export type TDealFileAttachment = {
    id: string;
    type: EDealAttachmentType.FILE;
    sourceName: string;
    contentType: string;
    contentSize: number;
    createdDate: Date;
};

export type TDealUrlAttachment = {
    id: string;
    type: EDealAttachmentType.URL;
    source: string;
    createdDate: Date;
};

export type TDealAttachment = TDealFileAttachment | TDealUrlAttachment;

export enum EDealAttachmentUploadDialogTab {
    fileUpload = 'file-upload',
    webLink = 'web-link',
}

export const mapDealAttachment = (
    attachment: DealAttachmentQl,
): TDealAttachment => {
    if (attachment.type.toString() === EDealAttachmentType.FILE) {
        return {
            id: attachment.id,
            type: EDealAttachmentType.FILE,
            sourceName: attachment.sourceName,
            contentType: attachment.contentType,
            contentSize: attachment.contentSize,
            createdDate: new Date(attachment.createdDate),
        } as TDealFileAttachment;
    }

    return {
        id: attachment.id,
        type: EDealAttachmentType.URL,
        source: attachment.source,
        createdDate: new Date(attachment.createdDate),
    } as TDealUrlAttachment;
};
