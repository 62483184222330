import { makeAutoObservable } from 'mobx';
import { getI18n, TFunction } from 'react-i18next';

import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { UserStore } from 'src/data/stores/user/user.store';

import { Language } from 'src/domain/models/locale/locale.model';
import { getFullName } from 'src/domain/models/user/user.model';
import { handleRequestAsync } from 'src/utils/handle-request.utils';

import { IContactExtractionFeature } from './contact-extraction-interface.feature';
import { IContactExtractionApi } from '../../data/contact-extraction.api';
import {
    ContactExtractionResult,
    ContactExtractionResultStatus,
    DealCompanyCandidate,
    DealContactCandidate,
} from '../../data/contact-extraction.model';
import { IContactExtractionStore } from '../../data/store/contact-extraction.interface.store';
import { doNothing } from 'src/utils/function.utils';
import { IAccountConfigurationStore } from 'src/data/stores/account-configuration/account-configuration.store.interface';
import {
    ActionFeatureIdentifierNamesMap,
    ActionFeatureLaunchSource,
    ActionFeaturesIdentifiers,
} from 'src/domain/features/account-configuration/helpers/action-feature.helper';
import { encodeStringHighlightToUrl } from 'src/utils/url.utils';
import { MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';

export class ContactExtractionFeature implements IContactExtractionFeature {
    isFlyoutOpen: boolean = false;

    get isFeatureEnabled() {
        return this.accountConfigurationStore.isActionFeatureEnabled(
            ActionFeaturesIdentifiers.contact_extractor,
        );
    }
    get contactExtractionResultStatus():
        | ContactExtractionResultStatus
        | undefined {
        return this.contactExtractionStore.contactExtractionResultStatus;
    }

    get contactExtractionCandidates(): DealContactCandidate[] {
        return this.contactExtractionStore.contactExtractionCandidates;
    }

    get companyExtractionCandidates(): DealCompanyCandidate[] {
        return this.contactExtractionStore.companyExtractionCandidates;
    }

    get selectedContactCandidates(): DealContactCandidate[] {
        return Array.from(
            this.contactExtractionStore.selectedContactCandidates,
        );
    }

    get selectedCompanyCandidates(): DealCompanyCandidate[] {
        return Array.from(
            this.contactExtractionStore.selectedCompanyCandidates,
        );
    }

    onSelectContactCandidate = (
        candidate: DealContactCandidate,
        selected: boolean,
    ) => {
        const company = this.companyExtractionCandidates.find(
            (company) => company.name === candidate.companyName,
        );
        if (selected) {
            this.contactExtractionStore.selectContact(candidate);

            if (company) {
                this.contactExtractionStore.selectCompany(company);
            }
        } else {
            this.contactExtractionStore.deselectContact(candidate);
            if (
                !this.selectedContactCandidates.filter(
                    (c) => c.companyName === candidate.companyName,
                ).length &&
                company
            ) {
                this.contactExtractionStore.deselectCompany(
                    company as DealCompanyCandidate,
                );
            }
        }
    };

    onSelectCompanyCandidate = (
        candidate: DealCompanyCandidate,
        selected: boolean,
    ) => {
        if (selected) {
            this.contactExtractionStore.selectCompany(candidate);
        } else if (
            !this.selectedContactCandidates.find(
                (c) => c.companyName === c.companyName,
            )
        ) {
            this.contactExtractionStore.deselectCompany(candidate);
        }
    };

    getAutoExtractionResult = async (dealId: string) => {
        this.setContactExtractionResult(undefined);
        this.setContactExtractionResultStatus(
            ContactExtractionResultStatus.InProgress,
        );
        const response = await handleRequestAsync(
            this.contactExtractionApi.getAutoExtractionResult,
            { dealId },
            doNothing,
            (error) => {
                this.baseStore.onRequestFailed(
                    'request-auto-extract-contacts',
                    error,
                );
                if (error) {
                    this.setContactExtractionResultStatus(
                        ContactExtractionResultStatus.Error,
                    );
                }
            },
            'getAutoExtractionResult',
        );
        if (response) {
            this.setContactExtractionResult(response);
        }
    };

    rerunAutoExtraction = async (dealId: string) => {
        this.setContactExtractionResultStatus(
            ContactExtractionResultStatus.InProgress,
        );
        await handleRequestAsync(this.contactExtractionApi.runAutoExtraction, {
            dealId,
            language: this.userStore.user?.language ?? Language.De,
        });
        this.getAutoExtractionResult(dealId);
    };

    setContactExtractionCandidates = (candidates: DealContactCandidate[]) => {
        this.contactExtractionStore.setContactExtractionCandidates(candidates);
    };

    openFlyout = (dealId: string, launchSource: ActionFeatureLaunchSource) => {
        this.isFlyoutOpen = true;
        this.mixpanelService.trackEvent(
            MixpanelEventName.LaunchedQuickAction,
            {
                quickActionName:
                    ActionFeatureIdentifierNamesMap[
                        ActionFeaturesIdentifiers.contact_extractor
                    ],
                launchSource: launchSource.toString(),
            },
            dealId,
        );
    };

    closeFlyout = () => {
        this.isFlyoutOpen = false;
    };

    clear = () => {
        this.contactExtractionStore.clear();
    };

    t: TFunction<'translation', undefined> = getI18n().t;

    constructor(
        private contactExtractionApi: IContactExtractionApi,
        private contactExtractionStore: IContactExtractionStore,
        private baseStore: IBaseStore,
        private userStore: UserStore,
        private accountConfigurationStore: IAccountConfigurationStore,
        private mixpanelService: MixpanelService,
    ) {
        makeAutoObservable(this);
    }

    setContactExtractionResultStatus = (
        status?: ContactExtractionResultStatus,
    ) => {
        this.contactExtractionStore.setContactExtractionResultStatus(status);
    };

    setCompanyExtractionCandidates = (candidates: DealCompanyCandidate[]) => {
        this.contactExtractionStore.setCompanyExtractionCandidates(candidates);
    };

    setContactExtractionResult = (result?: ContactExtractionResult) => {
        if (!result) {
            this.clear();
            return;
        }
        result.contactCandidates.forEach((item) => {
            item.sourceUrls = item.sourceUrls.map((url) =>
                encodeStringHighlightToUrl(
                    url,
                    getFullName(item.firstName, item.lastName),
                ),
            );
        });
        result.companyCandidates.forEach((item) => {
            item.sourceUrls = item.sourceUrls.map((url) =>
                encodeStringHighlightToUrl(url, item.name),
            );
        });
        this.setContactExtractionCandidates(result.contactCandidates);
        this.setCompanyExtractionCandidates(result.companyCandidates);
        if (
            result.status === ContactExtractionResultStatus.Success &&
            result.contactCandidates.length > 0
        ) {
            this.onSelectContactCandidate(
                this.contactExtractionCandidates[0],
                true,
            );
        }
        this.setContactExtractionResultStatus(result.status);
    };
}
