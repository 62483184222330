import { makeAutoObservable } from 'mobx';
import { getI18n } from 'react-i18next';
import { type PostHog } from 'posthog-js/react';
import { type IPostHogService } from 'src/data/services/posthog/posthog.service';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';
import { MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { type IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { type ToasterStore } from 'src/data/stores/toaster/toaster.store';
import { sortNewArray } from 'src/utils/array.utils';
import {
    type Cancellable,
    handleRequest,
    handleRequestAsync,
} from 'src/utils/handle-request.utils';
import { Localized } from 'src/presentation/shared/localized/localized';
import { type IDealAttachmentsApi } from '../data/deal-attachments.api';
import {
    type TDealAttachment,
    type TDealUrlAttachment,
    EDealAttachmentType,
    TDealFileAttachment,
} from '../domain/deal-attachments.model';

export interface IDealAttachmentsFeature {
    attachmentList: TDealAttachment[];
    attachedFile: File | null;
    attachedWebLink: string | null;
    isAttachmentDialogOpen: boolean;
    areAttachmentsLoading: boolean;
    isFileAttachmentUrlLoading: boolean;
    isAttachmentsLoadingFailed: boolean;
    isAttachmentUploading: boolean;
    isAttachmentDeleting: boolean;
    fetchAttachmentList: (dealId: string) => Cancellable;
    openFileAttachment: (
        dealId: string,
        attachment: TDealFileAttachment,
    ) => Promise<void>;
    trackURLAttachmentClick: (
        dealId: string,
        attachment: TDealUrlAttachment,
    ) => void;
    uploadAttachment: (
        dealId: string,
        attachment: File | string,
        postHog: PostHog,
    ) => Promise<void>;
    deleteAttachment: (
        dealId: string,
        attachment: TDealAttachment,
    ) => Promise<void>;
    setAttachedFile: (file: File | null) => void;
    setAttachedWebLink: (webLink: string | null) => void;
    setIsAttachmentDialogOpen: (isOpen: boolean) => void;
    resetAttachmentDialog: () => void;
}

export class DealAttachmentsFeature implements IDealAttachmentsFeature {
    constructor(
        private dealAttachmentsApi: IDealAttachmentsApi,
        private baseStore: IBaseStore,
        private toasterStore: ToasterStore,
        private mixpanelService: MixpanelService,
        private postHogService: IPostHogService,
    ) {
        makeAutoObservable(this);
    }

    attachmentList: IDealAttachmentsFeature['attachmentList'] = [];
    attachedFile: IDealAttachmentsFeature['attachedFile'] = null;
    attachedWebLink: IDealAttachmentsFeature['attachedWebLink'] = null;
    isAttachmentDialogOpen: IDealAttachmentsFeature['isAttachmentDialogOpen'] =
        false;
    areAttachmentsLoading: IDealAttachmentsFeature['areAttachmentsLoading'] =
        false;
    isFileAttachmentUrlLoading: IDealAttachmentsFeature['isFileAttachmentUrlLoading'] =
        false;
    isAttachmentsLoadingFailed: IDealAttachmentsFeature['isAttachmentsLoadingFailed'] =
        false;
    isAttachmentUploading: IDealAttachmentsFeature['isAttachmentUploading'] =
        false;
    isAttachmentDeleting: IDealAttachmentsFeature['isAttachmentDeleting'] =
        false;

    fetchAttachmentList: IDealAttachmentsFeature['fetchAttachmentList'] = (
        dealId,
    ) => {
        return handleRequest(
            this.dealAttachmentsApi.getAttachmentList,
            { dealId },
            (attachmentList) => {
                if (attachmentList) {
                    this.attachmentList = sortNewArray(attachmentList)(
                        (a, b) =>
                            b.createdDate.getTime() - a.createdDate.getTime(),
                    );
                }
            },
            (isLoading) => {
                this.areAttachmentsLoading = isLoading;
            },
            (error) => {
                if (error) {
                    this.baseStore.onRequestFailed(
                        'upload-deal-attachment',
                        error as Error,
                    );
                }

                this.isAttachmentsLoadingFailed = !!error;
            },
        );
    };

    openFileAttachment = async (
        dealId: string,
        attachment: TDealFileAttachment,
    ) => {
        const fileUrl = await handleRequestAsync(
            this.dealAttachmentsApi.getFileAttachmentDownloadUrl,
            { dealId, attachmentId: attachment.id },
            (isLoading) => {
                this.isFileAttachmentUrlLoading = isLoading;
            },
            (error) => {
                if (error) {
                    this.baseStore.onRequestFailed(
                        'get-deal-file-attachment-url',
                        error as Error,
                    );
                }
            },
        );

        if (fileUrl) {
            window.open(fileUrl, '_blank', 'noopener,noreferrer');

            this.mixpanelService.trackEvent(
                MixpanelEventName.OpenedDealResource,
                { dealAttachmentName: attachment.sourceName },
                dealId,
            );
        }
    };

    trackURLAttachmentClick = (
        dealId: string,
        attachment: TDealUrlAttachment,
    ) => {
        this.mixpanelService.trackEvent(
            MixpanelEventName.OpenedDealResource,
            { dealAttachmentName: attachment.source },
            dealId,
        );
    };

    uploadAttachment = async (
        dealId: string,
        attachment: File | string,
        postHog: PostHog,
    ) => {
        const isFile = attachment instanceof File;
        const params = isFile
            ? { dealId, file: attachment }
            : { dealId, url: attachment };

        let isError = false;

        const newAttachment = await handleRequestAsync(
            this.dealAttachmentsApi.uploadAttachment,
            params,
            (isUploading) => {
                this.isAttachmentUploading = isUploading;
            },
            (error) => {
                if (error) {
                    isError = true;
                    this.baseStore.onRequestFailed(
                        'upload-deal-attachment',
                        error as Error,
                    );
                }
            },
        );

        if (isError) return;

        this.isAttachmentDialogOpen = false;

        if (newAttachment) {
            this.attachmentList = [newAttachment, ...this.attachmentList];

            const attachmentName =
                newAttachment.type === EDealAttachmentType.FILE
                    ? newAttachment.sourceName
                    : newAttachment.source;

            this.mixpanelService.trackEvent(
                MixpanelEventName.AttachedDealResource,
                { dealAttachmentName: attachmentName },
                dealId,
            );

            this.postHogService.capture(
                postHog,
                MixpanelEventName.AttachedDealResource,
                { dealId, dealAttachmentName: attachmentName },
            );
        }

        const { t } = getI18n();

        this.toasterStore.showMessage({
            type: 'success',
            title: t<string>('deal_attachments.attachment_add_success.title'),
            message: t<string>('deal_attachments.attachment_add_success.text'),
        });

        // Delay reset to prevent layout jump during dialog close animation.
        setTimeout(() => {
            this.resetAttachmentDialog();
        }, 100);
    };

    deleteAttachment: IDealAttachmentsFeature['deleteAttachment'] = async (
        dealId,
        attachment,
    ) => {
        let isError = false;

        await handleRequestAsync(
            this.dealAttachmentsApi.deleteAttachment,
            {
                dealId,
                attachmentId: attachment.id,
            },
            (isDeleting) => {
                this.isAttachmentDeleting = isDeleting;
            },
            (error) => {
                if (error) {
                    isError = true;
                    this.baseStore.onRequestFailed(
                        'delete-deal-attachment',
                        error as Error,
                    );
                }
            },
        );

        if (isError) return;

        this.attachmentList = this.attachmentList.filter(
            (item) => item.id !== attachment.id,
        );

        const attachmentName =
            attachment.type === EDealAttachmentType.FILE
                ? attachment.sourceName
                : attachment.source;

        const { t } = getI18n();

        this.toasterStore.showMessage({
            type: 'success',
            title: t('deal_attachments.attachment_delete_success.title'),
            message: (
                <Localized
                    components={{
                        resourceName: (
                            <span className="font-bold">{attachmentName}</span>
                        ),
                    }}
                    placeholderMap={{
                        name: attachmentName,
                    }}
                    shouldUnescape
                >
                    deal_attachments.attachment_delete_success.text
                </Localized>
            ),
        });

        this.mixpanelService.trackEvent(
            MixpanelEventName.DeletedDealResource,
            { dealAttachmentName: attachmentName },
            dealId,
        );
    };

    setAttachedFile: IDealAttachmentsFeature['setAttachedFile'] = (file) => {
        this.attachedFile = file;
    };

    setAttachedWebLink: IDealAttachmentsFeature['setAttachedWebLink'] = (
        webLink,
    ) => {
        this.attachedWebLink = webLink;
    };

    setIsAttachmentDialogOpen: IDealAttachmentsFeature['setIsAttachmentDialogOpen'] =
        (isOpen) => {
            this.isAttachmentDialogOpen = isOpen;
        };

    resetAttachmentDialog: IDealAttachmentsFeature['resetAttachmentDialog'] =
        () => {
            this.attachedFile = null;
            this.attachedWebLink = null;
        };
}
