import { encode } from 'js-base64';
import { makeAutoObservable, runInAction, reaction } from 'mobx';
import { getI18n } from 'react-i18next';
import { IDealContactsApi } from 'src/app-features/contact/data/api/deal-contacts.api.types';

import {
    type DealContactUnionType,
    DealContactPerson,
    DealContactType,
    PredefinedCustomFields,
} from 'src/app-features/contact/data/model/deal-contacts.model';
import { IDealContactsStore as IDealContactsStoreNew } from 'src/app-features/contact/data/stores/interfaces/deal-contacts.store.interface';
import { IDealActivityStore } from 'src/app-features/deal-activity/domain/deal-activity.store';
import {
    CreateContactVCardPayload,
    ISaveContactToPhoneService,
} from 'src/data/services/save-contact-to-phone/save-contact-to-phone.service';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { ToasterStore } from 'src/data/stores/toaster/toaster.store';
import { arrayToMap } from 'src/utils/array.utils';
import { doNothing } from 'src/utils/function.utils';
import { handleRequest } from 'src/utils/handle-request.utils';

export interface IQrCodeFeature {
    /**
     * Observable
     * Url of a generated contact Qr Code, used as image source to display the QR code
     */
    currentContactQrCodeUrl?: string;
    /**
     * Action
     * Use the current vCard to build a blob file and download it directly from the browser
     */
    downloadContactVCard: (contact?: DealContactPerson) => void;
    /**
     * Async Action
     * Send vCardCurrentContact data to given phone number via sms
     */
    sendContactWithSMS: (
        phoneNumber: string,
        contact?: DealContactPerson,
    ) => void;
}

export class QrCodeFeature implements IQrCodeFeature {
    currentContactQrCodeUrl?: string;

    constructor(
        private dealContactsStoreNew: IDealContactsStoreNew,
        private saveContactToPhoneService: ISaveContactToPhoneService,
        private dealContactsApi: IDealContactsApi,
        private toasterStore: ToasterStore,
        private dealActivityStore: IDealActivityStore,
        private baseStore: IBaseStore,
    ) {
        makeAutoObservable(this);

        reaction(
            () => this.vCardCurrentContact(),
            async (vCard) => {
                await this.buildAndSetContactQrCode(vCard);
            },
        );
    }

    get selectedContact(): DealContactUnionType | undefined {
        return this.dealActivityStore.outreachActivationState.contact;
    }

    downloadContactVCard = (contact?: DealContactUnionType) => {
        const _contact = contact ?? this.selectedContact;
        if (!_contact) return;

        const vCard = this.vCardCurrentContact(contact);
        if (!vCard) return;

        const isCompany = _contact.type === DealContactType.company;
        let vCardFileName;

        if (isCompany) {
            vCardFileName = _contact.name;
        } else {
            vCardFileName = `${_contact.firstName}${
                _contact.lastName ? `_${_contact.lastName}` : ''
            }`;
        }

        this.saveContactToPhoneService.downloadVCard(vCard, vCardFileName);
    };

    private buildAndSetContactQrCode = async (vCard?: string) => {
        if (!vCard) {
            return;
        }

        const url = await this.saveContactToPhoneService.buildQrCodeUrl(vCard);

        runInAction(() => {
            this.currentContactQrCodeUrl = url;
        });
    };

    sendContactWithSMS = async (
        phoneNumber: string,
        contact?: DealContactPerson,
    ) => {
        const vCard = this.vCardCurrentContact(contact);
        if (!vCard) {
            return;
        }
        const { t } = getI18n();

        handleRequest(
            this.dealContactsApi.sendContactWithSMS,
            {
                phoneNumber,
                vcardB64: encode(vCard),
            },
            () => {
                this.toasterStore.showMessage({
                    title: t(
                        'actions.save_contact_to_phone.contact_sent_success',
                        { phoneNumber },
                    ),
                    type: 'success',
                });
            },
            doNothing,
            (error) =>
                this.baseStore.onRequestFailed('send-contact-sms', error, {
                    errorMessage: t(
                        'actions.save_contact_to_phone.contact_sent_fail',
                        {
                            phoneNumber,
                        },
                    ),
                }),
        );
    };

    vCardCurrentContact = (
        contactPerson?: DealContactUnionType,
    ): string | undefined => {
        const contact = contactPerson ?? this.selectedContact;
        if (!contact) return undefined;

        const isCompany = contact.type === DealContactType.company;
        let vCardMeta: CreateContactVCardPayload;

        const customFieldMap = arrayToMap(contact.customFields ?? [], 'name');

        if (isCompany) {
            vCardMeta = {
                organization: contact.name,
                workPhone: customFieldMap.get(PredefinedCustomFields.telephone)
                    ?.value,
                workEmail: customFieldMap.get(PredefinedCustomFields.mail)
                    ?.value,
                workAddress: customFieldMap.get(PredefinedCustomFields.address)
                    ?.value,
                isOrganization: true,
            };
        } else {
            const linkedCompany = contact.relevantCompanyId
                ? this.dealContactsStoreNew.companiesMap.get(
                      contact.relevantCompanyId,
                  )
                : undefined;

            vCardMeta = {
                firstName: contact.firstName,
                lastName: contact.lastName,
                organization: contact.relevantCompany?.name,
                workPhone: customFieldMap.get(PredefinedCustomFields.telephone)
                    ?.value,
                cellPhone: customFieldMap.get(PredefinedCustomFields.mobile)
                    ?.value,
                workEmail: customFieldMap.get(PredefinedCustomFields.mail)
                    ?.value,
                title: customFieldMap.get(PredefinedCustomFields.position)
                    ?.value,
                workAddress: linkedCompany?.customFields?.find(
                    ({ name }) => name === PredefinedCustomFields.address,
                )?.value,
            };
        }

        return this.saveContactToPhoneService.buildContactVCard(vCardMeta);
    };
}
