import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { TFunction, getI18n } from 'react-i18next';
import { AchievementService } from 'src/app-features/achievements/domain/service/achievements.service';
import {
    IDealContactsApi,
    DealContactsResponse,
} from 'src/app-features/contact/data/api/deal-contacts.api.types';
import {
    DealContactCompanyForm,
    DealContactPersonForm,
} from 'src/app-features/contact/data/model/deal-contacts-form.model';
import { RoleToContactMap } from 'src/app-features/contact/data/model/deal-contacts.interface';
import {
    DealContactPerson,
    DealContactCompany,
    DealContactType,
    PredefinedCustomFields,
    TextInputStatus,
} from 'src/app-features/contact/data/model/deal-contacts.model';
import {
    ContactOrCompanyCreationSource,
    IDealContactsTrackingService,
} from 'src/app-features/contact/data/services/deal-contacts-tracking.service';
import { IDealContactsStore } from 'src/app-features/contact/data/stores/interfaces/deal-contacts.store.interface';
import { UserAchievementName } from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';
import {
    EventProps,
    MixpanelService,
} from 'src/data/services/mixpanel/mixpanel.service';
import { IAccountConfigurationStore } from 'src/data/stores/account-configuration/account-configuration.store.interface';
import { DealsStore } from 'src/data/stores/deals/deals.store';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { ToasterStore } from 'src/data/stores/toaster/toaster.store';
import { CustomParameterConfig } from 'src/app-features/custom-parameter/data/model/custom-parameter.model';

import { doNothing } from 'src/utils/function.utils';
import {
    Cancellable,
    handleRequest,
    handleRequestAsync,
} from 'src/utils/handle-request.utils';

import {
    shouldRunAutoEnrichmentForCompany,
    sortContact,
    syncCompaniesChangesInContacts,
    syncContactsChangesInCompanies,
} from './deal-contacts.utils';
import {
    DealContactCandidate,
    DealCompanyCandidate,
    DealContactCandidateForm,
    DealCompanyCandidateForm,
} from 'src/app-features/contact-extraction/data/contact-extraction.model';
import { IGoogleEnrichmentApi } from 'src/app-features/google-enrichment/data/google-enrichment.api';
import { DataProviderSource } from 'src/app-features/contact-enrichment/domain/contact-enrichment.model';
import { getValidItemsLength } from '../../google-enrichment/persentation/auto-google-enrichment/utils';
import { isNotEmpty } from 'src/utils/string.utils';
import {
    GoogleEnrichmentOption,
    GoogleEnrichmentResultKey,
} from 'src/app-features/google-enrichment/data/google-enrichment.model';
import { UserStore } from 'src/data/stores/user/user.store';
import { IDealContactsPresentationStore } from '../data/stores/interfaces/deal-contacts-presentation.store.interface';
import {
    CompanyEnrichmentReportPayload,
    ContactUpdateMetricsInfo,
    IDealContactsFeature,
    RoleUpdatePayload,
    RoleUpdateSource,
} from './interfaces/deal-contacts.feature.interface';

export class DealContactsFeature implements IDealContactsFeature {
    t: TFunction<'translation', undefined> = getI18n().t;

    constructor(
        private dealContactsPresentationStore: IDealContactsPresentationStore,
        private dealContactsStore: IDealContactsStore,
        private dealContactsApi: IDealContactsApi,
        private googleEnrichmentApi: IGoogleEnrichmentApi,
        private baseStore: IBaseStore,
        private mixpanelService: MixpanelService,
        private dealsStore: DealsStore,
        private toasterStore: ToasterStore,
        private accountConfigurationStore: IAccountConfigurationStore,
        private achievementService: AchievementService,
        private userStore: UserStore,
        private dealContactsTrackingService: IDealContactsTrackingService,
    ) {
        makeAutoObservable(this);
        reaction(
            () => this.allDealContactPersons,
            (contacts) => {
                runInAction(() => {
                    syncContactsChangesInCompanies(
                        contacts,
                        this.allDealContactCompanies,
                    );
                });
            },
        );

        reaction(
            () => this.allDealContactCompanies,
            (companies) => {
                runInAction(() => {
                    syncCompaniesChangesInContacts(
                        companies,
                        this.allDealContactPersons,
                    );
                });
            },
        );
    }

    /**
     * getter section
     */
    get allDealContactPersons(): DealContactPerson[] {
        return Array.from(this.dealContactsStore.personsMap.values()).sort(
            sortContact,
        );
    }
    get allDealContactCompanies(): DealContactCompany[] {
        return Array.from(this.dealContactsStore.companiesMap.values()).sort(
            sortContact,
        );
    }
    get keyDealContactPersons(): DealContactPerson[] {
        return this.allDealContactPersons.filter(({ isKey }) => isKey);
    }
    get keyDealContactCompanies(): DealContactCompany[] {
        return this.allDealContactCompanies.filter(({ isKey }) => isKey);
    }
    get archivedDealContactPersons(): DealContactPerson[] {
        return this.allDealContactPersons.filter(({ isKey }) => !isKey);
    }
    get archivedDealContactCompanies(): DealContactCompany[] {
        return this.allDealContactCompanies.filter(({ isKey }) => !isKey);
    }
    get contactPersonCustomFields(): CustomParameterConfig[] {
        return (
            this.accountConfigurationStore.accountConfiguration
                ?.contactCustomFields ?? []
        );
    }
    get contactCompanyCustomFields(): CustomParameterConfig[] {
        return (
            this.accountConfigurationStore.accountConfiguration
                ?.companyCustomFields ?? []
        );
    }
    get contactPersonRoles(): string[] {
        return (
            this.accountConfigurationStore.accountConfiguration?.contactRoles ??
            []
        );
    }
    get contactCompanyRoles(): string[] {
        return (
            this.accountConfigurationStore.accountConfiguration?.companyRoles ??
            []
        );
    }
    get dealRolesInUse(): RoleToContactMap {
        return [
            ...this.allDealContactPersons,
            ...this.allDealContactCompanies,
        ].reduce((result: RoleToContactMap, contact) => {
            const dealRole = contact?.role;

            if (dealRole) {
                result[dealRole] = contact.id;
            }

            return result;
        }, {});
    }
    /**
     * setter section
     */

    setRequestDealContactsInProgress = (flag: boolean) => {
        this.dealContactsPresentationStore.requestDealContactsInProgress = flag;
    };

    setCreateContactInProgress = (flag: boolean) => {
        this.dealContactsPresentationStore.createContactInProgress = flag;
    };

    /**
     * implementation
     */

    requestDealContacts = (dealId: string): Cancellable => {
        this.dealContactsStore.clear();
        return handleRequest(
            this.dealContactsApi.getDealContacts,
            { dealId },
            (response) => this.onRequestDealContactsResponse(response, dealId),
            this.setRequestDealContactsInProgress,
            (error) =>
                this.baseStore.onRequestFailed('request-deal-companies', error),
        );
    };

    createDealContactPerson = (
        formData: DealContactPersonForm,
        dealId: string,
        onSuccess?: (contact: DealContactPerson) => void,
        creationSource?: ContactOrCompanyCreationSource,
    ) => {
        handleRequest(
            this.dealContactsApi.createContactPerson,
            {
                dealId,
                data: {
                    ...formData,
                    firstName: formData.firstName?.trim(),
                    lastName: formData.lastName?.trim(),
                },
            },
            this.onCreateContactPerson(dealId, onSuccess, creationSource),
            this.setCreateContactInProgress,
            (error) => this.baseStore.onRequestFailed('create-contact', error),
        );
    };

    createDealContactCompany = (
        data: DealContactCompanyForm,
        dealId: string,
        onSuccess?: (company: DealContactCompany) => void,
        creationSource?: ContactOrCompanyCreationSource,
    ) => {
        handleRequest(
            this.dealContactsApi.createContactCompany,
            {
                dealId,
                data: {
                    ...data,
                    name: data.name?.trim(),
                },
            },
            this.onCreateContactCompany(dealId, onSuccess, creationSource),
            this.setCreateContactInProgress,
            (error) => this.baseStore.onRequestFailed('create-company', error),
        );
    };

    updateFieldProgress = (key: string | string[], status: TextInputStatus) => {
        if (Array.isArray(key)) {
            key.forEach((keyItem) =>
                this.dealContactsPresentationStore.fieldProgress.set(
                    keyItem,
                    status,
                ),
            );
        } else {
            this.dealContactsPresentationStore.fieldProgress.set(key, status);
        }
    };

    updateDealContactPerson = (
        dealId: string,
        id: string,
        update: DealContactPersonForm,
        metricsInfo?: ContactUpdateMetricsInfo,
        onSuccess?: (contact: DealContactPerson) => void,
    ) => {
        handleRequest(
            this.dealContactsApi.updateContactPerson,
            {
                contactId: id,
                update: {
                    ...update,
                    firstName: update.firstName?.trim(),
                    lastName: update.lastName?.trim(),
                },
            },
            this.onUpdateContactPerson(dealId, metricsInfo, onSuccess),
            (flag: boolean) => {
                if (flag && metricsInfo?.field) {
                    this.updateFieldProgress(metricsInfo?.field, 'progress');
                }
            },
            (error) => {
                this.baseStore.onRequestFailed('update-contact', error);
                if (error && metricsInfo?.field) {
                    this.updateFieldProgress(metricsInfo?.field, 'error');
                }
            },
        );
    };

    updateDealContactCompany = (
        dealId: string,
        id: string,
        update: DealContactCompanyForm,
        metricsInfo?: ContactUpdateMetricsInfo,
        onSuccess?: (company: DealContactCompany) => void,
    ) => {
        handleRequest(
            this.dealContactsApi.updateContactCompany,
            {
                relevantCompanyId: id,
                update: {
                    ...update,
                    name: update.name?.trim(),
                },
            },
            this.onUpdateContactCompany(dealId, metricsInfo, onSuccess),
            (flag: boolean) => {
                if (flag && metricsInfo?.field) {
                    this.updateFieldProgress(metricsInfo?.field, 'progress');
                }
            },
            (error) => {
                this.baseStore.onRequestFailed('update-company', error);
                if (error && metricsInfo?.field) {
                    this.updateFieldProgress(metricsInfo?.field, 'error');
                }
            },
        );
    };

    toggleContactArchiveStatus = (
        dealId: string,
        contactId: string,
        contactType: DealContactType,
        archive: boolean,
        roleToUnarchive?: string,
    ) => {
        if (contactType === DealContactType.person) {
            this.updateDealContactPerson(dealId, contactId, {
                isKey: !archive,
                formType: contactType,
                role: archive ? '' : roleToUnarchive,
            });
        } else {
            this.updateDealContactCompany(dealId, contactId, {
                isKey: !archive,
                formType: contactType,
                role: archive ? '' : roleToUnarchive,
            });
            const shouldRunAutoEnrichment = shouldRunAutoEnrichmentForCompany(
                this.userStore.user,
                this.dealsStore.getDeal(dealId),
                this.dealContactsStore.companiesMap.get(contactId),
            );
            if (shouldRunAutoEnrichment) {
                this.runAutoCompanyEnrichment(dealId, contactId);
            }
        }

        this.dealContactsTrackingService.trackToggleContactOrCompanyArchiveStatus(
            dealId,
            contactId,
            contactType,
            archive,
        );
    };

    deleteDealContactPerson = (dealId: string, contactId: string) => {
        handleRequest(
            this.dealContactsApi.deleteContactPerson,
            { dealId, contactId },
            (ok) => this.onDeleteContactPerson(ok, contactId),
            doNothing,
            (error) => this.baseStore.onRequestFailed('delete-contact', error),
        );
    };

    deleteDealContactCompany = (
        dealId: string,
        companyId: string,
        deleteAssociatedContacts: boolean,
    ) => {
        handleRequest(
            this.dealContactsApi.deleteContactCompany,
            { dealId, dealCompanyId: companyId, deleteAssociatedContacts },
            (success) =>
                this.onDeleteContactCompany(
                    success,
                    companyId,
                    deleteAssociatedContacts,
                ),
            doNothing,
            (error) => this.baseStore.onRequestFailed('delete-company', error),
        );
    };

    updateDealContactPersonRole = ({
        dealId,
        contactId,
        role,
        isKey,
        source,
        toasterMessage,
        callbackOnUndo,
    }: RoleUpdatePayload) => {
        handleRequest(
            this.dealContactsApi.updateContactPersonRole,
            { dealPersonId: contactId, role, isKey },
            (dealContactPerson?: DealContactPerson) =>
                this.onDealContactPersonRoleUpdated(
                    dealId,
                    source,
                    dealContactPerson,
                    toasterMessage,
                    callbackOnUndo,
                ),
            doNothing,
            (error) =>
                this.baseStore.onRequestFailed('update-contact-role', error),
        );
    };

    updateDealContactCompanyRole = ({
        dealId,
        contactId,
        role,
        isKey,
        source,
        toasterMessage,
        callbackOnUndo,
    }: RoleUpdatePayload) => {
        handleRequest(
            this.dealContactsApi.updateContactCompanyRole,
            { dealCompanyId: contactId, role, isKey },
            (dealCompany?: DealContactCompany) =>
                this.onDealContactCompanyRoleUpdated(
                    dealId,
                    source,
                    dealCompany,
                    toasterMessage,
                    callbackOnUndo,
                ),
            doNothing,
            (error) =>
                this.baseStore.onRequestFailed('update-company-role', error),
        );
    };

    createContactFromCandidates = async (
        contactCandidates: DealContactCandidate[],
        companyCandidates: DealCompanyCandidate[],
        dealId: string,
    ) => {
        const accountHasPositionCustomField =
            !!this.accountConfigurationStore.getCustomParameterByName(
                PredefinedCustomFields.position,
                'contactCustomFields',
            );

        const extractedContactsPayload = contactCandidates.map((contact) => {
            const notes = this.formatContactExtractedNotes(
                contact,
                accountHasPositionCustomField,
            );
            const shouldSaveRoleAsPosition =
                accountHasPositionCustomField && !!contact.role;

            return {
                firstName: contact.firstName,
                lastName: contact.lastName,
                companyName: contact.companyName,
                notes,
                customValues: shouldSaveRoleAsPosition
                    ? [
                          {
                              name: PredefinedCustomFields.position,
                              value: contact.role,
                          },
                      ]
                    : undefined,
            } as DealContactCandidateForm;
        });

        const extractedCompaniesPayload = companyCandidates.map((contact) => {
            const notes = this.formatContactExtractedNotes(
                contact,
                accountHasPositionCustomField,
            );
            const shouldSaveRoleAsPosition =
                accountHasPositionCustomField && !!contact.role;

            return {
                name: contact.name,
                notes,
                customValues: shouldSaveRoleAsPosition
                    ? [
                          {
                              name: PredefinedCustomFields.position,
                              value: contact.role,
                          },
                      ]
                    : undefined,
            } as DealCompanyCandidateForm;
        });

        const created = await handleRequestAsync(
            this.dealContactsApi.batchCreateContactWithCompany,
            {
                dealId,
                extractedContacts: extractedContactsPayload,
                extractedCompanies: extractedCompaniesPayload,
            },
            this.setCreateContactInProgress,
            (error) => this.baseStore.onRequestFailed('create-contact', error),
        );

        this.dealContactsTrackingService?.trackContactOrCompanyBatchCreation(
            created?.contacts ?? [],
            dealId,
            ContactOrCompanyCreationSource.ContactExtractor,
        );

        this.dealContactsTrackingService?.trackContactOrCompanyBatchCreation(
            created?.companies ?? [],
            dealId,
            ContactOrCompanyCreationSource.ContactExtractor,
        );

        created?.companies?.forEach(async (company) => {
            await this.runAutoCompanyEnrichment(dealId, company.id);
        });

        this.achievementService.call(
            UserAchievementName.CreateYourFirstContact,
        );
        this.requestDealContacts(dealId);
    };

    formatContactExtractedNotes = (
        contact: DealContactCandidate | DealCompanyCandidate,
        contactPositionFieldExists: boolean,
    ) => {
        let notes = '';

        if (!contactPositionFieldExists && contact.role) {
            notes += `${this.t('enablement_panel.contact_extraction.create.notes.job_title')}: ${
                contact.role
            }. \n\n`;
        }

        notes += `${this.t('enablement_panel.contact_extraction.create.notes.context')}: ${
            contact.legitimateInterestText
        }. \n\n${this.t(
            'enablement_panel.contact_extraction.create.notes.sources',
        )}: \n${contact.sourceUrls.map((url, index) => `[${index}]${url}`).join('\n')}`;

        return notes;
    };

    findContactById = (id: string, type: DealContactType) => {
        if (type === DealContactType.person) {
            return this.dealContactsStore.personsMap.get(id);
        } else {
            return this.dealContactsStore.companiesMap.get(id);
        }
    };

    runAutoCompanyEnrichment = async (
        dealId: string,
        companyId: string,
        showToaster?: boolean,
    ) => {
        try {
            const result = await handleRequestAsync(
                this.googleEnrichmentApi.runAutoEnrichmentForCompany,
                { dealId, companyId },
            );
            if (result) {
                this.onAutoEnrichmentSuccess(result, showToaster);
            }
        } catch (error) {
            this.onAutoEnrichmentError(error as Error);
        }
    };

    removeEnrichmentItemFromCompany = async ({
        dealId,
        companyId,
        placeId,
        field,
        metricsInfo,
    }: CompanyEnrichmentReportPayload): Promise<void> => {
        try {
            const result = await handleRequestAsync(
                this.googleEnrichmentApi.removeEnrichmentItemFromCompany,
                { companyId, placeId, field },
            );
            if (result) {
                this.toasterStore.showMessage({
                    title: this.t(
                        'deal_view.contacts_section.toast.auto_enrichment_result_removed_title',
                    ),
                    message: this.t(
                        'deal_view.contacts_section.toast.auto_enrichment_result_removed',
                    ),
                    type: 'success',
                });
                this.onEnrichmentDataReported({
                    dealId,
                    originalEnrichmentOptions:
                        this.allDealContactCompanies.find(
                            (c) => c.id === companyId,
                        )?.enrichmentOptions,
                    company: result,
                    placeId,
                    field,
                    type: 'Rejected',
                    displayIndex: metricsInfo.displayIndex,
                    selectedFromField: metricsInfo.selectedFromField,
                    rejectionReason: metricsInfo.disqualifyReason,
                    rejectionText: metricsInfo.disqualifyText,
                });
            }
        } catch (error) {
            this.onAutoEnrichmentError(error as Error);
        }
    };

    updateCompanyWithEnrichmentResult = async ({
        dealId,
        companyId,
        placeId,
        field,
        metricsInfo,
    }: CompanyEnrichmentReportPayload): Promise<DealContactCompany | null> => {
        try {
            const result = await handleRequestAsync(
                this.googleEnrichmentApi.saveAutoEnrichmentResultForCompany,
                {
                    dealId,
                    companyId,
                    placeId,
                    fields: field ? [field] : undefined,
                },
            );
            if (result) {
                this.onEnrichmentDataReported({
                    dealId,
                    originalEnrichmentOptions:
                        this.allDealContactCompanies.find(
                            (c) => c.id === companyId,
                        )?.enrichmentOptions,
                    company: result,
                    placeId,
                    field,
                    type: 'Accepted',
                    displayIndex: metricsInfo.displayIndex,
                    selectedFromField: metricsInfo.selectedFromField,
                });
                return result;
            }
        } catch (error) {
            this.baseStore.onRequestFailed(
                'save-auto-enrichment-for-company',
                error as Error,
            );
        }
        return null;
    };

    /**
     * callbacks for API calls
     */

    onAutoEnrichmentSuccess = (
        company: DealContactCompany,
        showToaster?: boolean,
    ) => {
        this.dealContactsStore.updateContactCompany(company);
        if (showToaster && company.enrichmentOptions?.length === 0) {
            this.toasterStore.showMessage({
                title: this.t(
                    'deal_view.contacts_section.toast.auto_enrichment_no_results',
                ),
                type: 'warning',
            });
        }
    };
    onAutoEnrichmentError = (error?: Error) => {
        this.baseStore.onRequestFailed('auto-enrichment', error);
    };

    onEnrichmentDataReported = (data: {
        dealId: string;
        originalEnrichmentOptions: GoogleEnrichmentOption[] | undefined;
        company: DealContactCompany;
        placeId: string;
        field: GoogleEnrichmentResultKey | undefined;
        type: 'Accepted' | 'Rejected';
        displayIndex: number;
        selectedFromField: GoogleEnrichmentResultKey;
        rejectionReason?: string;
        rejectionText?: string;
    }) => {
        const {
            dealId,
            originalEnrichmentOptions,
            company,
            placeId,
            field,
            type,
            displayIndex,
            selectedFromField,
            rejectionReason,
            rejectionText,
        } = data;
        this.dealContactsStore.updateContactCompany(company);
        const option = originalEnrichmentOptions?.find(
            (op) => op.id === placeId,
        );
        if (!option || !originalEnrichmentOptions) return;
        const validIdentifiers = [
            isNotEmpty(option[PredefinedCustomFields.url])
                ? PredefinedCustomFields.url
                : '',
            isNotEmpty(option[PredefinedCustomFields.telephone])
                ? PredefinedCustomFields.telephone
                : '',
            isNotEmpty(option[PredefinedCustomFields.address])
                ? PredefinedCustomFields.address
                : '',
        ].filter(isNotEmpty);
        this.sendMixpanelEvent(
            MixpanelEventName.ReportedEnrichmentData,
            {
                companyId: company.id,
                reportType: type,
                enrichmentReportIndex: displayIndex + 1,
                enrichmentProviders: [DataProviderSource.Google],
                identifiersReportedEnrichment: field
                    ? [field]
                    : validIdentifiers,
                totalGooglePlacesForParameterFound: getValidItemsLength(
                    originalEnrichmentOptions,
                    selectedFromField,
                ),
                enrichmentMode: field
                    ? 'Single parameter'
                    : 'All available parameters',
                dataReportReasonSelection: rejectionReason,
                dataReportReasonText: rejectionText,
            },
            dealId,
        );
    };

    onRequestDealContactsResponse = (
        companyAndPersonData: DealContactsResponse,
        dealId: string,
    ) => {
        this.dealContactsStore.clear();
        companyAndPersonData.dealContactCompanies.forEach(async (company) => {
            this.dealContactsStore.addContactCompany(company);

            const shouldRunAutoEnrichment = shouldRunAutoEnrichmentForCompany(
                this.userStore.user,
                this.dealsStore.getDeal(dealId),
                company,
            );

            if (shouldRunAutoEnrichment) {
                await this.runAutoCompanyEnrichment(dealId, company.id);
            }
        });
        companyAndPersonData.dealContactPersons.forEach((contact) =>
            this.dealContactsStore.addContactPerson(contact),
        );
    };

    onCreateContactPerson =
        (
            dealId: string,
            onSuccess?: (contact: DealContactPerson) => void,
            creationSource?: ContactOrCompanyCreationSource,
        ) =>
        (contact: DealContactPerson | undefined) => {
            if (!contact) {
                return;
            }

            this.dealContactsStore.addContactPerson(contact);
            this.dealContactsTrackingService?.trackContactOrCompanyCreation(
                contact,
                dealId,
                creationSource,
            );
            onSuccess?.(contact);
            this.achievementService.call(
                UserAchievementName.CreateYourFirstContact,
            );
        };

    onCreateContactCompany =
        (
            dealId: string,
            onSuccess?: (company: DealContactCompany) => void,
            creationSource?: ContactOrCompanyCreationSource,
        ) =>
        (company: DealContactCompany | null | undefined) => {
            if (!company) {
                return;
            }

            this.dealContactsStore.addContactCompany(company);
            this.dealContactsTrackingService?.trackContactOrCompanyCreation(
                company,
                dealId,
                creationSource,
            );
            onSuccess?.(company);
            this.runAutoCompanyEnrichment(dealId, company.id);
        };

    onUpdateContactPerson =
        (
            dealId: string,
            metricsInfo?: ContactUpdateMetricsInfo,
            onSuccess?: (contact: DealContactPerson) => void,
        ) =>
        (contact: DealContactPerson) => {
            this.dealContactsTrackingService?.trackContactOrCompanyFieldEdition(
                contact,
                dealId,
                metricsInfo,
            );
            this.dealContactsStore.updateContactPerson(contact);
            this.achievementService.call(
                UserAchievementName.AddContactsEmailOrPhone,
            );
            if (metricsInfo?.field) {
                this.updateFieldProgress(metricsInfo?.field, 'success');
            }
            onSuccess?.(contact);
        };

    onUpdateContactCompany =
        (
            dealId: string,
            metricsInfo?: ContactUpdateMetricsInfo,
            onSuccess?: (company: DealContactCompany) => void,
        ) =>
        (company: DealContactCompany) => {
            this.dealContactsTrackingService?.trackContactOrCompanyFieldEdition(
                company,
                dealId,
                metricsInfo,
            );
            this.dealContactsStore.updateContactCompany(company);
            if (metricsInfo?.field) {
                this.updateFieldProgress(metricsInfo?.field, 'success');
            }
            onSuccess?.(company);
        };

    onDeleteContactCompany = (
        success: boolean,
        companyId: string,
        deleteAssociatedContacts: boolean,
    ) => {
        if (!success) {
            return;
        }

        const relevantPersons = Array.from(
            this.dealContactsStore.personsMap.values(),
        ).filter((person) => person.relevantCompanyId === companyId);
        relevantPersons.forEach((person) => {
            if (deleteAssociatedContacts) {
                this.dealContactsStore.removeContactPerson(person.id);
            } else {
                this.dealContactsStore.updateContactPerson({
                    ...person,
                    relevantCompany: undefined,
                    relevantCompanyId: undefined,
                });
            }
        });

        this.dealContactsStore.removeContactCompany(companyId);
    };

    onDeleteContactPerson = (success: boolean, contactId: string) => {
        if (success) {
            this.dealContactsStore.removeContactPerson(contactId);
        }
    };

    onDealContactPersonRoleUpdated = (
        dealId: string,
        source: RoleUpdateSource,
        updatedContactPerson: DealContactPerson | undefined,
        toasterMessage: string = this.t(
            'deal_view.contacts_section.toast.update-contact-deal-role',
        ),
        callbackOnUndo?: () => void,
    ) => {
        if (updatedContactPerson) {
            this.dealContactsStore.personsMap.set(
                updatedContactPerson.id,
                updatedContactPerson,
            );

            if (updatedContactPerson?.isKey) {
                this.toasterStore.showMessage({ title: toasterMessage });
            } else {
                if (callbackOnUndo) {
                    this.toasterStore.showMessage({
                        title: this.t(
                            'deal_view.contacts_section.toast.remove-contact-deal-role',
                        ),
                        primaryAction: callbackOnUndo,
                        primaryActionText: this.t('common.undo'),
                    });
                }
            }

            const { id, type, role } = updatedContactPerson;
            const roleToTrack = role?.length
                ? role
                : this.t('deal_view.contacts_section.role.no_deal_role');

            this.dealContactsTrackingService?.trackContactOrCompanyRoleUpdate(
                type,
                dealId,
                id,
                source,
                roleToTrack,
            );

            this.achievementService.call(
                UserAchievementName.AssignContactDealRole,
            );
        }
    };

    onDealContactCompanyRoleUpdated = (
        dealId: string,
        source: RoleUpdateSource,
        updatedContactCompany: DealContactCompany | undefined,
        toasterMessage: string = this.t(
            'deal_view.contacts_section.toast.update-company-deal-role',
        ),
        callbackOnUndo?: () => void,
    ) => {
        if (updatedContactCompany) {
            this.dealContactsStore.companiesMap.set(
                updatedContactCompany.id,
                updatedContactCompany,
            );
            if (updatedContactCompany.isKey) {
                this.toasterStore.showMessage({ title: toasterMessage });
            } else {
                if (callbackOnUndo) {
                    this.toasterStore.showMessage({
                        title: this.t(
                            'deal_view.contacts_section.toast.remove-company-deal-role',
                        ),
                        primaryAction: callbackOnUndo,
                        primaryActionText: this.t('common.undo'),
                    });
                }
            }

            const { id, type, role } = updatedContactCompany;
            const roleToTrack = role?.length
                ? role
                : this.t('deal_view.contacts_section.role.no_deal_role');

            this.dealContactsTrackingService?.trackContactOrCompanyRoleUpdate(
                type,
                dealId,
                id,
                source,
                roleToTrack,
            );
        }
    };

    sendMixpanelEvent = (
        eventName: MixpanelEventName,
        eventProperties?: EventProps,
        dealId?: string,
    ) => {
        this.mixpanelService.trackEvent(eventName, eventProperties, dealId);
    };
}
