import { AbortParams } from 'src/data/api/api-client';
import {
    PipelineStageStatisticsPartFragment,
    PipelineStatisticsDealPartFragment,
    SortingKeys,
    SortingOperators,
    ValueMilestoneFragment,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { ProcessGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';
import { CustomParameterConfig } from 'src/app-features/custom-parameter/data/model/custom-parameter.model';
import {
    createMinimalDealOfPipeline,
    MinimalDeal,
} from 'src/domain/models/deal/deal.model';
import {
    DynamicFilter,
    DynamicFilterMetric,
} from 'src/domain/models/dynamic-filter/dynamic-filter.model';
import {
    createPipeline,
    createStageStatistics,
    Pipeline,
    StageStatistics,
} from 'src/domain/models/pipeline/pipeline.model';

import {
    buildQueryFilters,
    getSafeStageId,
    getStageStatisticsQuery,
} from './pipeline.api.utils';
import { DealPipelineCustomField } from 'src/domain/models/deal-pipeline-data/deal-pipeline-data.model';
import { Reminder } from 'src/domain/models/reminder/reminder.model';
import {
    Comment,
    createComment,
} from 'src/domain/models/comment/comment.model';

export interface PipelineViewPreferencesParams {
    usersIds: number[];
    showPausedDeals: boolean;
    customParameterTagOptions: string[];
    stageIds: string[];
}

interface GetPipelineStatisticsParams extends AbortParams {
    pipelineId: string;
    pipelineName: string;
    stageIds: string[];
    viewPreferences?: PipelineViewPreferencesParams;
    dynamicFilters: DynamicFilter[];
    customParameterTag?: CustomParameterConfig;
}

interface GetPipelineDynamicFilterMetricsParams extends AbortParams {
    pipelineId: string;
    viewPreferences?: PipelineViewPreferencesParams;
    customParameterTag?: CustomParameterConfig;
}

interface GetByDealIdsParams extends AbortParams {
    dealIds: string[];
}

export interface PipelineApi {
    getPipelinesData: (params: AbortParams) => Promise<Pipeline[]>;
    getPipelineStatistics: (
        params: GetPipelineStatisticsParams,
    ) => Promise<StageStatistics[]>;
    getPipelineDynamicFilterMetrics: (
        params: GetPipelineDynamicFilterMetricsParams,
    ) => Promise<DynamicFilterMetric[]>;
    getValueMilestones: (
        params: AbortParams,
    ) => Promise<ValueMilestoneFragment[]>;
    getDealsPipelineCustomFields: ({
        dealIds,
        signal,
    }: GetByDealIdsParams) => Promise<Map<string, DealPipelineCustomField[]>>;
    getDealsPipelineReminders: ({
        dealIds,
        signal,
    }: GetByDealIdsParams) => Promise<Map<string, Reminder[]>>;
    getDealsPipelineComments: ({
        dealIds,
        signal,
    }: GetByDealIdsParams) => Promise<Map<string, Comment[]>>;
}

export const createPipelineAPi = (
    processGqlSdk: ProcessGqlSdk,
): PipelineApi => {
    const getPipelinesData = async (
        params: AbortParams,
    ): Promise<Pipeline[]> => {
        const response = await processGqlSdk.AllPipelinesQuery(
            {
                queryParams: {
                    sorting: [
                        {
                            field: SortingKeys.SkPipeCreatedDate,
                            sop: SortingOperators.SopAsc,
                        },
                    ],
                },
            },
            { signal: params.signal },
        );
        return response.allPipelines.map(createPipeline);
    };

    const getPipelineStatistics = async ({
        pipelineId,
        pipelineName,
        stageIds,
        viewPreferences,
        dynamicFilters,
        customParameterTag,
        signal,
    }: GetPipelineStatisticsParams): Promise<StageStatistics[]> => {
        const response = await processGqlSdk.rawGraphqlRequest<any>(
            getStageStatisticsQuery(
                stageIds,
                pipelineId,
                buildQueryFilters(viewPreferences, customParameterTag, true),
                dynamicFilters,
            ),
            {
                pipelineId,
                filters: buildQueryFilters(
                    viewPreferences,
                    customParameterTag,
                    false,
                ),
                dynamicFilters,
            },
            { signal },
        );

        if (!response.pipeline) {
            return [];
        }

        return response.pipeline.statistics.stages.map(
            (stageStatistics: PipelineStageStatisticsPartFragment) => {
                const safeStageId = getSafeStageId(stageStatistics.id);
                const dealsPerStage: PipelineStatisticsDealPartFragment[] =
                    response[safeStageId].edges.map(
                        (edge: { node: MinimalDeal }) => {
                            return { ...edge.node };
                        },
                    );
                const dealsPerStageCount = response[safeStageId].totalCount;
                return createStageStatistics(
                    stageStatistics,
                    dealsPerStage.map((deal) =>
                        createMinimalDealOfPipeline(deal, {
                            id: pipelineId,
                            name: pipelineName,
                        }),
                    ),
                    dealsPerStageCount,
                );
            },
        );
    };

    const getPipelineDynamicFilterMetrics = async ({
        pipelineId,
        viewPreferences,
        customParameterTag,
        signal,
    }: GetPipelineDynamicFilterMetricsParams) => {
        const response = await processGqlSdk.GetDynamicFilterMetrics(
            {
                pipelineId,
                filters: buildQueryFilters(
                    viewPreferences,
                    customParameterTag,
                    true,
                ),
            },
            { signal },
        );
        return response.pipeline.statistics.dynamicFiltersMetrics;
    };

    const getValueMilestones = async ({ signal }: AbortParams) => {
        const response = await processGqlSdk.GetValueMilestones({}, { signal });
        return response.allValueMilestones;
    };

    const getDealsPipelineCustomFields = async ({
        dealIds,
        signal,
    }: GetByDealIdsParams): Promise<Map<string, DealPipelineCustomField[]>> => {
        const { dealsCollection } = await processGqlSdk.GetCustomFieldsForDeals(
            { dealIds },
            { signal },
        );

        const customFieldsPerDealMap = (dealsCollection.edges ?? []).reduce(
            (acc, { node }) => {
                acc.set(
                    node.id,
                    (node.customFields ?? []).map((c) => ({
                        isTag: c.isTag,
                        label: c.label,
                        name: c.name,
                        value: c.value ?? undefined,
                    })),
                );

                return acc;
            },
            new Map<string, DealPipelineCustomField[]>(),
        );

        return customFieldsPerDealMap;
    };

    const getDealsPipelineReminders = async ({
        dealIds,
        signal,
    }: GetByDealIdsParams): Promise<Map<string, Reminder[]>> => {
        const { dealsCollection } = await processGqlSdk.GetRemindersForDeals(
            { dealIds },
            { signal },
        );

        const remindersPerDealMap = (dealsCollection.edges ?? []).reduce(
            (acc, { node }) => {
                acc.set(
                    node.id,
                    (node.reminders ?? []).map((r) => {
                        return new Reminder(r);
                    }),
                );

                return acc;
            },
            new Map<string, Reminder[]>(),
        );

        return remindersPerDealMap;
    };

    const getDealsPipelineComments = async ({
        dealIds,
        signal,
    }: GetByDealIdsParams): Promise<Map<string, Comment[]>> => {
        const { dealsCollection } = await processGqlSdk.GetCommentsForDeals(
            { dealIds },
            { signal },
        );

        const commentsPerDealMap = (dealsCollection.edges ?? []).reduce(
            (acc, { node }) => {
                acc.set(node.id, (node.comments ?? []).map(createComment));

                return acc;
            },
            new Map<string, Comment[]>(),
        );

        return commentsPerDealMap;
    };

    return {
        getPipelinesData,
        getPipelineStatistics,
        getPipelineDynamicFilterMetrics,
        getValueMilestones,
        getDealsPipelineCustomFields,
        getDealsPipelineReminders,
        getDealsPipelineComments,
    };
};
