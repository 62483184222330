import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Date with time (isoformat) */
  DateTime: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type DealAttachmentQl = {
  contentSize?: Maybe<Scalars['Int']['output']>;
  contentType?: Maybe<Scalars['String']['output']>;
  createdDate: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  source: Scalars['String']['output'];
  sourceName?: Maybe<Scalars['String']['output']>;
  type: DealAttachmentType;
};

export enum DealAttachmentType {
  File = 'FILE',
  Url = 'URL'
}

export type DealImportStatusQl = {
  ok: Scalars['Boolean']['output'];
  uploadSize: Scalars['Int']['output'];
};

export type DebugQl = {
  request: DebugRequestQl;
};

export type DebugRequestQl = {
  tracing: DebugRequestTracingQl;
};

export type DebugRequestTracingQl = {
  traceId?: Maybe<Scalars['String']['output']>;
};

export type MultipartMutation = {
  requestDealImport: DealImportStatusQl;
  uploadDealAttachment: Array<DealAttachmentQl>;
};


export type MultipartMutationRequestDealImportArgs = {
  externalSourceUpload?: InputMaybe<Scalars['Upload']['input']>;
  externalSourceUrl?: InputMaybe<Scalars['String']['input']>;
  targetPipelineId: Scalars['String']['input'];
};


export type MultipartMutationUploadDealAttachmentArgs = {
  dealId: Scalars['String']['input'];
  file?: InputMaybe<Scalars['Upload']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type MultipartQuery = {
  debug: DebugQl;
  timeoutTest: Scalars['String']['output'];
  translate: Scalars['String']['output'];
};


export type MultipartQueryTimeoutTestArgs = {
  timeoutSec?: Scalars['Int']['input'];
};


export type MultipartQueryTranslateArgs = {
  language: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export type DealAttachmentFragment = { id: string, type: DealAttachmentType, source: string, sourceName?: string | null, contentType?: string | null, contentSize?: number | null, createdDate: any };

export type RequestDealImportMutationVariables = Exact<{
  targetPipelineId: Scalars['String']['input'];
  externalSourceUpload?: InputMaybe<Scalars['Upload']['input']>;
  externalSourceUrl?: InputMaybe<Scalars['String']['input']>;
}>;


export type RequestDealImportMutation = { requestDealImport: { ok: boolean, uploadSize: number } };

export type UploadDealAttachmentMutationVariables = Exact<{
  dealId: Scalars['String']['input'];
  file?: InputMaybe<Scalars['Upload']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
}>;


export type UploadDealAttachmentMutation = { uploadDealAttachment: Array<{ id: string, type: DealAttachmentType, source: string, sourceName?: string | null, contentType?: string | null, contentSize?: number | null, createdDate: any }> };

export const DealAttachmentFragmentDoc = gql`
    fragment DealAttachment on DealAttachmentQL {
  id
  type
  source
  sourceName
  contentType
  contentSize
  createdDate
}
    `;
export const RequestDealImportDocument = gql`
    mutation RequestDealImport($targetPipelineId: String!, $externalSourceUpload: Upload, $externalSourceUrl: String) {
  requestDealImport(
    targetPipelineId: $targetPipelineId
    externalSourceUrl: $externalSourceUrl
    externalSourceUpload: $externalSourceUpload
  ) {
    ok
    uploadSize
  }
}
    `;
export const UploadDealAttachmentDocument = gql`
    mutation UploadDealAttachment($dealId: String!, $file: Upload, $url: String) {
  uploadDealAttachment(dealId: $dealId, file: $file, url: $url) {
    ...DealAttachment
  }
}
    ${DealAttachmentFragmentDoc}`;
export type Requester<C = {}> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R> | AsyncIterable<R>
export function getSdk<C>(requester: Requester<C>) {
  return {
    RequestDealImport(variables: RequestDealImportMutationVariables, options?: C): Promise<RequestDealImportMutation> {
      return requester<RequestDealImportMutation, RequestDealImportMutationVariables>(RequestDealImportDocument, variables, options) as Promise<RequestDealImportMutation>;
    },
    UploadDealAttachment(variables: UploadDealAttachmentMutationVariables, options?: C): Promise<UploadDealAttachmentMutation> {
      return requester<UploadDealAttachmentMutation, UploadDealAttachmentMutationVariables>(UploadDealAttachmentDocument, variables, options) as Promise<UploadDealAttachmentMutation>;
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;