import * as Sentry from '@sentry/react';
import { DocumentNode, GraphQLError } from 'graphql';
import { cloneDeep } from 'lodash';
import { getSdk as getProcessSdk } from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { getSdk as getProcessMultipartSdk } from 'src/data/api/graphql/br_process_multipart/generated/graphql-sdk';
import { getSdk as getProjectSdk } from 'src/data/api/graphql/br_project/generated/graphql-sdk';
import { getSdk as getSearchSdk } from 'src/data/api/graphql/br_search/generated/graphql-sdk';
import { getSdk as getUserSdk } from 'src/data/api/graphql/br_user/generated/graphql-sdk';
import { assignError } from 'src/utils/error.utils';

import { Requester } from './br_process/generated/graphql-sdk';
import { WsSubscriptionClosedError } from './custom-errors/ws-subscription-closed-error';

import {
    ApolloClient,
    NormalizedCacheObject,
    OperationVariables,
    TypedDocumentNode,
} from '@apollo/client';
import { getGraphqlApolloClient } from './graphql-client.builder';
import { getOperationDefinition } from '@apollo/client/utilities';
import {
    processMultipartUrl,
    processUrl,
    projectUrl,
    searchUrl,
    userUrl,
} from './graphql-client.utils';
import { checkWebSocket } from 'src/utils/websocket.utils';

type GraphQLRequestOptions = {
    signal: AbortSignal;
};

type SubscribePayload<V extends OperationVariables> = {
    query: DocumentNode | TypedDocumentNode;
    variables: V;
};

const getExtendedGraphqlSdk = <A>(
    uri: string,
    getSdk: <C>(requester: Requester<C>) => A,
) => {
    const apolloClient: ApolloClient<NormalizedCacheObject> =
        getGraphqlApolloClient(uri, false);
    const websocketClient: ApolloClient<NormalizedCacheObject> =
        getGraphqlApolloClient(uri, true);

    const requester: Requester<{ signal: AbortSignal }> = async <
        R,
        V extends OperationVariables = OperationVariables,
    >(
        doc: DocumentNode,
        vars?: unknown,
        options?: GraphQLRequestOptions,
    ): Promise<R> => {
        if (getOperationDefinition(doc)?.operation === 'mutation') {
            const response = await apolloClient.mutate<R, V>({
                mutation: doc,
                variables: vars as V,
                context: { fetchOptions: options },
            });
            if (response.data) {
                return cloneDeep(response.data);
            } else {
                throw (
                    response.errors?.[0] ?? new Error('GraphQL mutation failed')
                );
            }
        }
        const response = await apolloClient.query<R, V>({
            query: doc,
            variables: vars as V,
            context: { fetchOptions: options },
        });
        if (response.error || response.errors) {
            throw (
                response.error ??
                response.errors?.[0] ??
                new Error('GraphQL query failed')
            );
        } else {
            return cloneDeep(response.data);
        }
    };

    /* T as Subscription types, e.g. ContactExtractionGetContactsInDealSubscription */
    const runSubscription = async <T, V extends OperationVariables>(
        payload: SubscribePayload<V>,
        onNextValue: (value: T) => void,
        onSubscriptionCompleted: () => void,
        onSubscriptionError: (errorMessage: Error) => void,
        signal: AbortSignal,
    ) => {
        const clientToUse = (await checkWebSocket())
            ? websocketClient
            : apolloClient;
        const observable = clientToUse.subscribe<T, V>({
            query: payload.query,
            variables: payload.variables,
        });
        const subscription = observable.subscribe({
            next: (result) => {
                if (result.data) {
                    onNextValue(cloneDeep(result.data));
                }
            },
            error: (error: Error | GraphQLError[] | CloseEvent) => {
                let capturedError: Error | WsSubscriptionClosedError;

                if (error instanceof Error) {
                    capturedError = assignError(error);
                } else if (Array.isArray(error)) {
                    capturedError = new Error(
                        error.map((err) => err.message).join(' / '),
                    );
                } else {
                    console.error(
                        'WebSocket JSON current target:',
                        JSON.stringify(error.currentTarget),
                    );
                    console.error(
                        'WebSocket JSON target:',
                        JSON.stringify(error.target),
                    );
                    console.error(
                        'JSON closedEvent from ws subscription',
                        JSON.stringify(error),
                    );
                    console.error('closedEvent from ws subscription', error);
                    capturedError = new WsSubscriptionClosedError(
                        'The subscription connection was closed due an error CloseEvent',
                        error,
                        JSON.stringify(error),
                    );
                }
                onSubscriptionError(capturedError);
                Sentry.captureException(capturedError);
                subscription?.unsubscribe();
            },
            complete: () => {
                if (!signal.aborted) {
                    onSubscriptionCompleted();
                }
                subscription?.unsubscribe();
            },
        });
        signal.onabort = () => {
            subscription.unsubscribe();
        };
    };

    return {
        ...getSdk<GraphQLRequestOptions>(requester),
        runSubscription: runSubscription,
        rawGraphqlRequest: async <R>(
            query: DocumentNode,
            variables?: OperationVariables,
            options?: GraphQLRequestOptions,
        ) => requester<R, OperationVariables>(query, variables, options),
    };
};

const buildBrProcessGraphqlClientSdk = () => {
    return getExtendedGraphqlSdk(processUrl, getProcessSdk);
};

const buildBrProcessMultipartGraphqlClientSdk = () => {
    return getExtendedGraphqlSdk(processMultipartUrl, getProcessMultipartSdk);
};

const buildBrUserGraphqlClientSdk = () =>
    getExtendedGraphqlSdk(userUrl, getUserSdk);

const buildBrSearchGraphqlClientSdk = () =>
    getExtendedGraphqlSdk(searchUrl, getSearchSdk);

const buildBrProjectGraphqlClientSdk = () =>
    getExtendedGraphqlSdk(projectUrl, getProjectSdk);

/**
 * The SDK used to interact to our br_process backend endpoints.
 */
export const ProcessGqlSdkWrapper = buildBrProcessGraphqlClientSdk();
export type ProcessGqlSdk = typeof ProcessGqlSdkWrapper;

/**
 * The SDK used to interact to our br_process backend endpoints with CSRF (for multipart requests security) enabled.
 */
export const ProcessMultipartGqlSdkWrapper =
    buildBrProcessMultipartGraphqlClientSdk();
export type ProcessMultipartGqlSdk = typeof ProcessMultipartGqlSdkWrapper;

/**
 * The SDK used to interact to our br_user backend endpoints.
 */
export const UserGqlSdkWrapper = buildBrUserGraphqlClientSdk();
export type UserGqlSdk = typeof UserGqlSdkWrapper;

/**
 * The SDK used to interact to our br_search_user backend endpoints.
 */
export const SearchGqlSdkWrapper = buildBrSearchGraphqlClientSdk();
export type SearchGqlSdk = typeof SearchGqlSdkWrapper;

/**
 * The SDK used to interact to our br_project backend endpoints.
 */
export const ProjectGqlSdkWrapper = buildBrProjectGraphqlClientSdk();
export type ProjectGqlSdk = typeof ProjectGqlSdkWrapper;
