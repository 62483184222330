import { History } from 'history';

import { QueryParams } from 'src/data/api/api-client';
import {
    FilterKeys,
    FilterOpQl,
    SortingKeys,
    SortingOpQl,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import {
    apiFilterKeysToFields,
    apiSortingKeysToFields,
} from 'src/domain/features/deals-list/deals-list.feature.util';

interface TinyUrl {
    tiny: string;
    full: string;
}
export const getTinySourceUrl = (url: string, truncate: boolean): TinyUrl => {
    const { host } = new URL(url);
    let urlWithoutWWW = host.replace('www.', '');
    if (truncate) {
        urlWithoutWWW = `${urlWithoutWWW}/...`;
    }
    return { tiny: urlWithoutWWW, full: url };
};

/**
 * create url from path and query
 * @param url
 * @param query
 */
export const buildUrl = (url: string, query?: QueryParams): string => {
    const searchParams = new URLSearchParams(query);
    return `${url}${query ? '?' + searchParams.toString() : ''}`;
};

export const addProtocolIfNeeded = (url: string): string => {
    if (url.startsWith('http://') || url.startsWith('https://')) {
        return url;
    }
    return '//' + url;
};

export const getUrlMatch = (stringToTest: string): string | undefined => {
    const regex = new RegExp(/\b(?:https?:\/\/|www\.)\S+\b/gim);
    return stringToTest.match(regex)?.[0];
};

export const isUrl = (stringToTest: string): boolean => {
    return !!getUrlMatch(stringToTest);
};

export const isValidUrl = (url: string | null | undefined): boolean => {
    if (!url) return false;

    try {
        new URL(url);
        return true;
    } catch {
        return false;
    }
};

const buildSortOption = (field: SortingKeys, order: string): string => {
    return `&field=${apiSortingKeysToFields[field]}&order=${order}`;
};

const buildFilterOption = (
    field: FilterKeys,
    order: string,
    value?: string | null,
    values?: string[] | null,
): string => {
    const fieldValue = apiFilterKeysToFields[field];
    const searchValue = value ? `&value=${value}` : `&value=[${values}]`;
    return `&field=${fieldValue}${searchValue}&fop=${order}|`;
};

export const getSortAndFilterOptions = () => {
    const [sort, filter] = window.location.search
        .split(/\?[a-zA-Z]+=/g)
        .filter((param) => param !== '');
    return { sort, filter };
};

export const splitUrlByQuery = (url: string): string[] => {
    return url.split(/&[a-zA-Z]+=/g).filter((param) => param !== '');
};

export const buildFilterValue = (value: string): string | string[] => {
    return value.includes('[') ? value.replace(/\[|\]/g, '').split(',') : value;
};

export const filterValueByField = (
    filterUrl: string,
    field: string,
): string | undefined => {
    return filterUrl
        .split(field)[1]
        .match(/&value=(.*?)&/)?.[1]
        ?.replace('(?i)', '');
};

export const pushFieldQueryToUrlHandler = (
    history: History,
    filterOptions: FilterOpQl[],
    sortOptions: SortingOpQl[],
) => {
    let query = history.location.pathname;
    const previousLocation =
        history.location.pathname + history.location.search;
    query += '?sort=';
    sortOptions.forEach(({ field, sop }) => {
        query += buildSortOption(field, sop);
    });
    const isFilterApplied = filterOptions.length > 0;
    if (isFilterApplied) {
        query += '?filter=';
        filterOptions.forEach(({ field, value, values, fop }) => {
            query += buildFilterOption(field, fop, value, values);
        });
    }
    if (previousLocation !== query) {
        history.replace(query);
    }
};

export const openLink = (url: string) => {
    window.open(addProtocolIfNeeded(url), '__blank', 'noreferrer');
};

export const buildGoogleMapsLink = (location: string) => {
    return `https://www.google.com/maps/search/?api=1&query=${location}`;
};

export const buildLinkedinLink = (username: string) => {
    return `https://www.linkedin.com/in/${username}`;
};

export const openGoogleMapsHandler = (location: string) =>
    window.open(buildGoogleMapsLink(location), '_blank');

/**
 * Utility function to open mailto link. If the configured mail client is setup in the browser,
 * it will open the mail client in another tab.
 * @param mailto
 */
export const goToMailTo = (mailto: {
    email: string;
    subject?: string;
    body?: string;
}) => {
    const { email, subject, body } = mailto;
    const mailTo = `mailto:${email}${
        subject ? `?subject=${encodeURIComponent(subject.trim())}` : ``
    }${body ? `&body=${encodeURIComponent(body)}` : ``}
    `;
    window.open(mailTo, '_blank');
};

export const reload = () => window.location.reload();

/**
 * Remove http(s):// and www. from url
 * @param url The url to remove the prefix from
 * @returns The url without the prefix
 */
export const removeUrlPrefix = (url: string) =>
    url.replace('https://', '').replace('http://', '').replace('www.', '');

export const encodeStringHighlightToUrl = (url: string, highlight: string) =>
    `${url}#:~:text=${encodeURIComponent(highlight)}`;

export const removeUrlQueryAndPrefix = (url: string) =>
    removeUrlPrefix(url.split('?')[0]);

export const ExternalApiDocsLink = new URL(
    `https://${window.location.host}${
        import.meta.env.VITE_EXTERNAL_API_DOCS_URL
    }`,
).href;
