import { makeAutoObservable } from 'mobx';

import { PipelineApi } from 'src/data/api/pipeline/pipeline.api';
import { IAccountConfigurationStore } from 'src/data/stores/account-configuration/account-configuration.store.interface';
import { PipelineStore } from 'src/data/stores/pipeline/pipeline.store';
import { IPipelineDynamicFiltersStore } from 'src/data/stores/pipeline-data/dynamic-filters/pipeline-dynamic-filters.store.interface';
import { IViewPreferencesStore } from 'src/data/stores/pipeline-data/view-preferences/view-preferences.store.interface';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import {
    DynamicFilter,
    DynamicFilterMetric,
    DynamicFilterToTrackingName,
    orderedDynamicFilters,
} from 'src/domain/models/dynamic-filter/dynamic-filter.model';
import {
    getPipelineViewDataTypeBasedOnVisualizationMode,
    RefetchPipelineViewDataSource,
} from 'src/domain/models/performance-view-preferences/performance-view-preferences.model';
import { StageStatistics } from 'src/domain/models/pipeline/pipeline.model';
import { handleRequestAsync } from 'src/utils/handle-request.utils';

import { sortDealsByStageConversion } from './pipeline-data.feature.util';
import { MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';
import { DealPipelineCustomField } from 'src/domain/models/deal-pipeline-data/deal-pipeline-data.model';
import { RequestStatus } from 'src/data/api/utils/request-status';
import { Reminder } from 'src/domain/models/reminder/reminder.model';
import { Comment } from 'src/domain/models/comment/comment.model';

export interface IPipelineDataFeature {
    /**
     * Data used to display the pipeline information in the `Box view` mode
     */
    stageStatistics: Map<string, StageStatistics>;
    statisticsLoadingStatus: RequestStatus;
    selectedDynamicFilters: Set<DynamicFilter>;
    availableDynamicFilters: DynamicFilter[];
    isDynamicFilterActive: boolean;
    isDynamicFiltersLoading: boolean;
    dealsCustomFieldsMap: Map<string, DealPipelineCustomField[]>;
    dealsRemindersMap: Map<string, Reminder[]>;
    dealsCommentsMap: Map<string, Comment[]>;
    fetchingCustomFields: boolean;
    fetchingReminders: boolean;
    fetchingComments: boolean;
    requestStageStatistics: (pipelineId: string, stageIds: string[]) => void;
    getDynamicFilterCount: (filter: DynamicFilter) => number;
    toggleDynamicFilter: (filter: DynamicFilter) => void;
    isDynamicFilterEnabled: (filter: DynamicFilter) => boolean;
    clearDynamicFilters: () => void;
    requestPipelineDynamicFilterMetrics: (pipelineId: string) => Promise<void>;
    requestPipelineDealsCustomFields: (dealIds: string[]) => Promise<void>;
    requestPipelineDealsReminders: (dealIds: string[]) => Promise<void>;
    requestPipelineDealsComments: (dealIds: string[]) => Promise<void>;
    clearStageStatistics: () => void;
    clearDealExtraData: () => void;
}

export function createPipelineDataFeatureObservable(
    pipelineApi: PipelineApi,
    performanceViewPreferencesStore: IViewPreferencesStore,
    pipelineDynamicFiltersStore: IPipelineDynamicFiltersStore,
    pipelineStore: PipelineStore,
    accountConfigurationStore: IAccountConfigurationStore,
    baseStore: IBaseStore,
    mixpanelService: MixpanelService,
): IPipelineDataFeature {
    const feature = new PipelineDataFeature(
        pipelineApi,
        performanceViewPreferencesStore,
        pipelineDynamicFiltersStore,
        pipelineStore,
        accountConfigurationStore,
        baseStore,
        mixpanelService,
    );

    makeAutoObservable(feature);

    return feature;
}

export class PipelineDataFeature implements IPipelineDataFeature {
    stageStatistics = new Map<string, StageStatistics>();
    statisticsLoadingStatus: RequestStatus = 'not-started';
    dynamicFilterMetrics = [] as DynamicFilterMetric[];

    fetchingCustomFields = false;
    dealsCustomFieldsMap = new Map<string, DealPipelineCustomField[]>();
    fetchingReminders = false;
    dealsRemindersMap = new Map<string, Reminder[]>();
    fetchingComments = false;
    dealsCommentsMap = new Map<string, Comment[]>();

    constructor(
        private pipelineApi: PipelineApi,
        private performanceViewPreferencesStore: IViewPreferencesStore,
        private pipelineDynamicFiltersStore: IPipelineDynamicFiltersStore,
        private pipelineStore: PipelineStore,
        private accountConfigurationStore: IAccountConfigurationStore,
        private baseStore: IBaseStore,
        private mixpanelService: MixpanelService,
    ) {}

    get isDynamicFilterActive(): boolean {
        return !!this.selectedDynamicFilters.size;
    }

    get isDynamicFiltersLoading(): boolean {
        return this.pipelineDynamicFiltersStore.isDynamicFiltersLoading;
    }

    get isStatisticsLoadingStatus() {
        return (
            this.statisticsLoadingStatus === 'loading' ||
            this.isDynamicFiltersLoading
        );
    }

    get selectedDynamicFilters() {
        return this.pipelineDynamicFiltersStore.selectedDynamicFilters;
    }

    get availableDynamicFilters() {
        return orderedDynamicFilters.filter(
            (d) => this.getDynamicFilterCount(d) > 0,
        );
    }

    getDynamicFilterCount = (filter: DynamicFilter) => {
        const filterMetric = this.dynamicFilterMetrics.find(
            ({ dynamicFilter }) => dynamicFilter === filter,
        );

        return filterMetric?.count ?? 0;
    };

    toggleDynamicFilter = (filter: DynamicFilter) => {
        if (this.selectedDynamicFilters.has(filter)) {
            this.selectedDynamicFilters.delete(filter);
            this.trackFilterToggleOnMixpanel(filter, false);
        } else {
            this.selectedDynamicFilters.add(filter);
            this.trackFilterToggleOnMixpanel(filter, true);
        }

        const pipelineVisualizationMode =
            this.performanceViewPreferencesStore.visualizationModePreference;

        this.performanceViewPreferencesStore.setRefetchPipelineViewDataState({
            active: true,
            refetchType: getPipelineViewDataTypeBasedOnVisualizationMode(
                pipelineVisualizationMode,
            ),
            source: RefetchPipelineViewDataSource.dynamicFilters,
        });
    };

    isDynamicFilterEnabled = (filter: DynamicFilter) => {
        return this.selectedDynamicFilters.has(filter);
    };

    clearDynamicFilters = () => {
        if (this.selectedDynamicFilters.size) {
            this.selectedDynamicFilters.forEach((filter) => {
                this.trackFilterToggleOnMixpanel(filter, false);
            });

            this.pipelineDynamicFiltersStore.clearDynamicFilters();

            const pipelineVisualizationMode =
                this.performanceViewPreferencesStore
                    .visualizationModePreference;

            this.performanceViewPreferencesStore.setRefetchPipelineViewDataState(
                {
                    active: true,
                    refetchType:
                        getPipelineViewDataTypeBasedOnVisualizationMode(
                            pipelineVisualizationMode,
                        ),
                    source: RefetchPipelineViewDataSource.dynamicFilters,
                },
            );
        }
    };

    updateDynamicFilters = () => {
        this.pipelineDynamicFiltersStore.setDynamicFilters(
            new Set(
                [...this.selectedDynamicFilters].filter(
                    (df) => this.getDynamicFilterCount(df) > 0,
                ),
            ),
        );
    };

    onRequestStageStatistics = (stageStatistics: StageStatistics[]) => {
        this.stageStatistics = new Map<string, StageStatistics>();
        if (stageStatistics) {
            stageStatistics.forEach((stageStatistic) => {
                stageStatistic.inPlayDealsOfStage =
                    stageStatistic.inPlayDealsOfStage.sort(
                        sortDealsByStageConversion,
                    );
                this.stageStatistics.set(stageStatistic.id, stageStatistic);
            });
            this.performanceViewPreferencesStore.setRefetchPipelineViewDataState(
                { active: false },
            );
        }
    };

    requestStageStatistics = async (pipelineId: string, stageIds: string[]) => {
        const viewPreferences =
            this.buildCurrentPipelineViewPreferencesParams();

        const pipeline = this.pipelineStore.getPipeline(pipelineId);

        if (!pipeline) {
            return;
        }

        this.statisticsLoadingStatus = 'loading';

        try {
            const statistics = await handleRequestAsync(
                this.pipelineApi.getPipelineStatistics,
                {
                    pipelineId,
                    pipelineName: pipeline.name,
                    stageIds,
                    viewPreferences,
                    dynamicFilters: Array.from(this.selectedDynamicFilters),
                    customParameterTag:
                        this.accountConfigurationStore.customParameterTag,
                },
                undefined,
                undefined,
                'request-stage-statistics',
            );

            this.statisticsLoadingStatus = 'done';
            if (statistics) {
                this.onRequestStageStatistics(statistics);
            }
        } catch (error) {
            this.baseStore.onRequestFailed(
                'request-stage-statistics',
                error as Error,
            );

            this.statisticsLoadingStatus = 'error';
        }
    };

    requestPipelineDynamicFilterMetrics = async (pipelineId: string) => {
        const viewPreferences =
            this.buildCurrentPipelineViewPreferencesParams();
        const dynamicFilterMetrics = await handleRequestAsync(
            this.pipelineApi.getPipelineDynamicFilterMetrics,
            {
                pipelineId,
                viewPreferences,
                customParameterTag:
                    this.accountConfigurationStore.customParameterTag,
            },
            this.pipelineDynamicFiltersStore.setIsDynamicFiltersLoading,
            (error) =>
                this.baseStore.onRequestFailed(
                    'request-dynamic-filters',
                    error,
                ),
            'request-dynamic-filters',
        );
        if (dynamicFilterMetrics?.length !== undefined) {
            this.dynamicFilterMetrics = dynamicFilterMetrics;
            this.updateDynamicFilters();
        }
    };

    requestPipelineDealsCustomFields = async (dealIds: string[]) => {
        try {
            if (!dealIds.length) return;

            const response = await handleRequestAsync(
                this.pipelineApi.getDealsPipelineCustomFields,
                {
                    dealIds,
                },
                (fetching) => (this.fetchingCustomFields = fetching),
            );

            if (response) {
                this.dealsCustomFieldsMap = response;
            }
        } catch (error) {
            this.baseStore.onRequestFailed(
                'request-pipeline-deals-custom-fields',
                error as Error,
            );
        }
    };

    requestPipelineDealsReminders = async (dealIds: string[]) => {
        try {
            if (!dealIds.length) return;

            const response = await handleRequestAsync(
                this.pipelineApi.getDealsPipelineReminders,
                {
                    dealIds,
                },
                (fetching) => (this.fetchingReminders = fetching),
            );

            if (response) {
                this.dealsRemindersMap = response;
            }
        } catch (error) {
            this.baseStore.onRequestFailed(
                'request-pipeline-deals-reminders',
                error as Error,
            );
        }
    };

    requestPipelineDealsComments = async (dealIds: string[]) => {
        try {
            if (!dealIds.length) return;

            const response = await handleRequestAsync(
                this.pipelineApi.getDealsPipelineComments,
                {
                    dealIds,
                },
                (fetching) => (this.fetchingComments = fetching),
            );

            if (response) {
                this.dealsCommentsMap = response;
            }
        } catch (error) {
            this.baseStore.onRequestFailed(
                'request-pipeline-deals-comments',
                error as Error,
            );
        }
    };

    buildCurrentPipelineViewPreferencesParams = () => {
        const customParameterTag =
            this.accountConfigurationStore.customParameterTag;

        return this.performanceViewPreferencesStore.getCurrentPipelineViewPreferencesParams(
            customParameterTag?.name,
        );
    };

    trackFilterToggleOnMixpanel = (
        filter: DynamicFilter,
        toggleOn: boolean,
    ) => {
        const event = toggleOn
            ? MixpanelEventName.AppliedDynamicFilter
            : MixpanelEventName.ClearedDynamicFilter;

        this.mixpanelService.trackEvent(event, {
            dynamicFilterName: DynamicFilterToTrackingName[filter],
        });
    };

    clearStageStatistics = () => {
        this.stageStatistics = new Map<string, StageStatistics>();
        this.statisticsLoadingStatus = 'not-started';
        this.clearDealExtraData();
    };

    clearDealExtraData = () => {
        this.dealsCommentsMap = new Map<string, Comment[]>();
        this.dealsRemindersMap = new Map<string, Reminder[]>();
        this.dealsCustomFieldsMap = new Map<
            string,
            DealPipelineCustomField[]
        >();
    };
}
