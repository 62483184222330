import { makeAutoObservable } from 'mobx';

import {
    DealContactCandidate,
    DealCompanyCandidate,
    ContactExtractionResultStatus,
} from 'src/app-features/contact-extraction/data/contact-extraction.model';
import { IContactExtractionStore } from './contact-extraction.interface.store';

export class ContactExtractionStore implements IContactExtractionStore {
    contactExtractionCandidates: DealContactCandidate[] = [];
    companyExtractionCandidates: DealCompanyCandidate[] = [];
    selectedCompanyCandidates: Set<DealCompanyCandidate> = new Set();
    selectedContactCandidates: Set<DealContactCandidate> = new Set();
    contactExtractionResultStatus?: ContactExtractionResultStatus;

    constructor() {
        makeAutoObservable(this);
    }
    setContactExtractionCandidates = (candidates: DealContactCandidate[]) => {
        this.contactExtractionCandidates = candidates;
    };
    setCompanyExtractionCandidates = (candidates: DealCompanyCandidate[]) => {
        this.companyExtractionCandidates = candidates;
    };

    selectContact = (candidate: DealContactCandidate) => {
        this.selectedContactCandidates.add(candidate);
    };

    deselectContact = (candidate: DealContactCandidate) => {
        this.selectedContactCandidates.delete(candidate);
    };

    selectCompany = (candidate: DealCompanyCandidate) => {
        this.selectedCompanyCandidates.add(candidate);
    };

    deselectCompany = (candidate: DealCompanyCandidate) => {
        this.selectedCompanyCandidates.delete(candidate);
    };

    setContactExtractionResultStatus = (
        status?: ContactExtractionResultStatus,
    ) => {
        this.contactExtractionResultStatus = status;
    };

    clear = () => {
        this.contactExtractionCandidates = [];
        this.companyExtractionCandidates = [];
        this.selectedCompanyCandidates = new Set();
        this.selectedContactCandidates = new Set();
        this.contactExtractionResultStatus = undefined;
    };
}
