import { DealCommentFragment } from 'src/data/api/graphql/br_process/generated/graphql-sdk';

export interface Comment {
    id: string;
    created: Date;
    updated: Date;
    text: string;
    authorId: number;
    dealId: string;
}

export const createComment = (rawComment: DealCommentFragment): Comment => {
    const { id, content, creatorBid, createdDate, updatedDate, parentId } =
        rawComment;
    return {
        id,
        created: new Date(createdDate),
        updated: new Date(updatedDate),
        authorId: creatorBid,
        text: content,
        dealId: parentId,
    };
};

export interface ParsedMentions {
    textWithMentions: string;
    plainText: string;
    mentions: number[];
}

export type SubmitCommentBehaviour = 'scroll' | 'message';
